<template>
<div class="card card-custom card-custom-scroll mb-3 mb-lg-0">
  <div class="card-body">
    <div class="row">
      <div class="col-4 ms-4 bg-light p-2 mb-3 d-flex justify-content-center align-items-center rounded">
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 115.428 115.428">
          <g transform="translate(2 2)">
            <path d="M57.715,2a55.714,55.714,0,1,0,55.713,55.715A55.778,55.778,0,0,0,57.715,2Zm0,12.1a43.69,43.69,0,0,1,42.257,32.81c.188,3.3-.858,5.9-3.288,5.9-13.029.008-23.791-13.029-38.966-13.029S31.772,52.821,18.751,52.813c-2.437-.006-3.484-2.6-3.294-5.9A43.691,43.691,0,0,1,57.715,14.1ZM48.633,58.573a9.084,9.084,0,1,1,9.084,9.084A9.083,9.083,0,0,1,48.633,58.573Zm-33.417,8.9a25.087,25.087,0,0,1,34.52,33.11A43.727,43.727,0,0,1,15.216,67.472ZM65.69,100.583a25.092,25.092,0,0,1,34.523-33.115A43.726,43.726,0,0,1,65.69,100.583Z" transform="translate(-2 -2)" fill="none" stroke="#6b6e7f" stroke-width="4"/>
          </g>
        </svg>
      </div>
    </div>
    <div class="d-flex">
      <div class="d-flex flex-wrap align-items-start justify-content-center gap-2">
        <span 
          v-for="asiento in asientosIzquierda" 
          :key="`asiento-izquierda-${asiento.id_asiento}`"
          class="asiento rounded mb-0 text-center"
          :class="[
            asiento.disponible ? 'color-green' : 'opacity-50', 
            asiento.selected ? 'selected' : '',
            editable ? (asiento.disponible ? 'link-cursor editable-item' : '') : '',
          ] "
          @click="clickAsiento(asiento)"
        >
          <template v-if="asiento.disponible">
            <svg xmlns="http://www.w3.org/2000/svg" class="asiento-svg" viewBox="0 0 167 140.869">
              <g transform="translate(165.5 153.188) rotate(180)">
                <path d="M48.721,79.135v53.579c0,10.493-6.553,19.018-14.619,19.018l-9.662.011v-89H36.118c6.973,0,12.6,7.378,12.6,16.395" transform="translate(115.279 -8.826)" fill="none" stroke="#e8e9f3" stroke-width="3"/>
                <path d="M24.44,79.135v53.579c0,10.493,6.553,19.018,14.619,19.018l9.662.011v-89H37.042c-6.973,0-12.6,7.378-12.6,16.395" transform="translate(-24.44 -8.826)" fill="none" stroke="#e8e9f3" stroke-width="3"/>
                <path d="M124.838,81.544H15.67A10.927,10.927,0,0,1,4.74,70.614v-4A16.394,16.394,0,0,1,21.135,50.219h98.238a16.394,16.394,0,0,1,16.395,16.395v4a10.927,10.927,0,0,1-10.93,10.93" transform="translate(11.746 -36.4)" fill="none" stroke="#e8e9f3" stroke-width="3"/>
                <g transform="translate(140.03 151.688) rotate(180)">
                  <g>
                    <path d="M112.329,98.932l-6.337,5.605a8,8,0,0,1-5.3,2.007h-85.9a8,8,0,0,1-5.154-1.882L2.846,98.941A8,8,0,0,1,0,92.823V8A8,8,0,0,1,8,0h99.029a8,8,0,0,1,8,8V92.939a8,8,0,0,1-2.7,5.993" fill="none" stroke="#e8e9f3" stroke-width="3"/>
                  </g>
                </g>
              </g>
            </svg>
            <span class="asiento-numero">{{ asiento.nombre_asiento }}</span>
          </template>
          <template v-else>
            <svg xmlns="http://www.w3.org/2000/svg" class="asiento-svg" viewBox="0 0 167 140.869">
              <g transform="translate(165.5 153.188) rotate(180)">
                <path d="M48.721,79.135v53.579c0,10.493-6.553,19.018-14.619,19.018l-9.662.011v-89H36.118c6.973,0,12.6,7.378,12.6,16.395" transform="translate(115.279 -8.826)" fill="#e8e9f3" stroke="#fff" stroke-width="3"/>
                <path d="M24.44,79.135v53.579c0,10.493,6.553,19.018,14.619,19.018l9.662.011v-89H37.042c-6.973,0-12.6,7.378-12.6,16.395" transform="translate(-24.44 -8.826)" fill="#e8e9f3" stroke="#fff" stroke-width="3"/>
                <path d="M124.838,81.544H15.67A10.927,10.927,0,0,1,4.74,70.614v-4A16.394,16.394,0,0,1,21.135,50.219h98.238a16.394,16.394,0,0,1,16.395,16.395v4a10.927,10.927,0,0,1-10.93,10.93" transform="translate(11.746 -36.4)" fill="#e8e9f3" stroke="#fff" stroke-width="3"/>
                <g transform="translate(140.03 151.688) rotate(180)">
                <g>
                  <path d="M112.329,98.932l-6.337,5.605a8,8,0,0,1-5.3,2.007h-85.9a8,8,0,0,1-5.154-1.882L2.846,98.941A8,8,0,0,1,0,92.823V8A8,8,0,0,1,8,0h99.029a8,8,0,0,1,8,8V92.939a8,8,0,0,1-2.7,5.993" fill="#e8e9f3" stroke="#fff" stroke-width="3"/>
                </g>
                </g>
              </g>
            </svg>
            <span class="asiento-numero opacity-50">{{ asiento.nombre_asiento }}</span>
          </template>
        </span>
      </div>
      <div class="bg-light mx-2" style="width:100px;"></div>
      <div class="d-flex flex-wrap align-items-start justify-content-center gap-2">
        <span 
          v-for="asiento in asientosDerecha" 
          :key="`asiento-derecha-${asiento.id_asiento}`"
          class="asiento rounded mb-0 text-center"
          :class="[
            asiento.disponible ? 'color-green' : 'opacity-50', 
            asiento.selected ? 'selected' : '',
            editable ? (asiento.disponible ? 'link-cursor editable-item' : '') : '',
          ] "
          @click="clickAsiento(asiento)"
        >
        <template v-if="asiento.disponible">
          <svg xmlns="http://www.w3.org/2000/svg" class="asiento-svg" viewBox="0 0 167 140.869">
            <g transform="translate(165.5 153.188) rotate(180)">
              <path d="M48.721,79.135v53.579c0,10.493-6.553,19.018-14.619,19.018l-9.662.011v-89H36.118c6.973,0,12.6,7.378,12.6,16.395" transform="translate(115.279 -8.826)" fill="none" stroke="#e8e9f3" stroke-width="3"/>
              <path d="M24.44,79.135v53.579c0,10.493,6.553,19.018,14.619,19.018l9.662.011v-89H37.042c-6.973,0-12.6,7.378-12.6,16.395" transform="translate(-24.44 -8.826)" fill="none" stroke="#e8e9f3" stroke-width="3"/>
              <path d="M124.838,81.544H15.67A10.927,10.927,0,0,1,4.74,70.614v-4A16.394,16.394,0,0,1,21.135,50.219h98.238a16.394,16.394,0,0,1,16.395,16.395v4a10.927,10.927,0,0,1-10.93,10.93" transform="translate(11.746 -36.4)" fill="none" stroke="#e8e9f3" stroke-width="3"/>
              <g transform="translate(140.03 151.688) rotate(180)">
                <g>
                  <path d="M112.329,98.932l-6.337,5.605a8,8,0,0,1-5.3,2.007h-85.9a8,8,0,0,1-5.154-1.882L2.846,98.941A8,8,0,0,1,0,92.823V8A8,8,0,0,1,8,0h99.029a8,8,0,0,1,8,8V92.939a8,8,0,0,1-2.7,5.993" fill="none" stroke="#e8e9f3" stroke-width="3"/>
                </g>
              </g>
            </g>
          </svg>
          <span class="asiento-numero">{{ asiento.nombre_asiento }}</span>
        </template>
        <template v-else>
          <svg xmlns="http://www.w3.org/2000/svg"  class="asiento-svg" viewBox="0 0 167 140.869">
            <g transform="translate(165.5 153.188) rotate(180)">
              <path d="M48.721,79.135v53.579c0,10.493-6.553,19.018-14.619,19.018l-9.662.011v-89H36.118c6.973,0,12.6,7.378,12.6,16.395" transform="translate(115.279 -8.826)" fill="#e8e9f3" stroke="#fff" stroke-width="3"/>
              <path d="M24.44,79.135v53.579c0,10.493,6.553,19.018,14.619,19.018l9.662.011v-89H37.042c-6.973,0-12.6,7.378-12.6,16.395" transform="translate(-24.44 -8.826)" fill="#e8e9f3" stroke="#fff" stroke-width="3"/>
              <path d="M124.838,81.544H15.67A10.927,10.927,0,0,1,4.74,70.614v-4A16.394,16.394,0,0,1,21.135,50.219h98.238a16.394,16.394,0,0,1,16.395,16.395v4a10.927,10.927,0,0,1-10.93,10.93" transform="translate(11.746 -36.4)" fill="#e8e9f3" stroke="#fff" stroke-width="3"/>
              <g transform="translate(140.03 151.688) rotate(180)">
              <g>
                <path d="M112.329,98.932l-6.337,5.605a8,8,0,0,1-5.3,2.007h-85.9a8,8,0,0,1-5.154-1.882L2.846,98.941A8,8,0,0,1,0,92.823V8A8,8,0,0,1,8,0h99.029a8,8,0,0,1,8,8V92.939a8,8,0,0,1-2.7,5.993" fill="#e8e9f3" stroke="#fff" stroke-width="3"/>
              </g>
              </g>
            </g>
          </svg>
              <span class="asiento-numero opacity-50">{{ asiento.nombre_asiento }}</span>
        </template>
        </span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    asientos: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean
    }
  },

  computed: {
    asientosIzquierda() {
      const asientosIzquierda = [];
      for (let i = 0; i < this.asientos.length; i += 4) {
        if (i < this.asientos.length) asientosIzquierda.push(this.asientos[i]);
        if (i+1 < this.asientos.length) asientosIzquierda.push(this.asientos[i + 1]);
      }
      return asientosIzquierda;
    },
    asientosDerecha() {
      const asientosDerecha = [];
      for (let i = 2; i < this.asientos.length; i += 4) {
        if (i < this.asientos.length) asientosDerecha.push(this.asientos[i]);
        if (i+1 < this.asientos.length) asientosDerecha.push(this.asientos[i + 1]);
      }
      return asientosDerecha;
    }
  },


  methods: {
    clickAsiento(asiento) {
      this.$emit("clickAsiento", asiento);
    },
    statusAsiento(asiento) {
      if (asiento.selected) {
        return "selected";
      } 
      if (!asiento.disponible) {
        return "inactive";
      }  
    }
  }

}
</script>