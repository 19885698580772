<template>
  <div class="question-modal">
    <div class="question-modal-container">
      <div class="question-modal-container-img">
        <div class="warning">
          <div class="img-container">
            <img :src="`${publicPath}imgs/question_face.svg`" alt="" />
          </div>
        </div>
      </div>
      <div class="question-modal-container-message" v-html="msg"></div>
      <div class="question-modal-container-buttons">
        <div class="cancel" v-if="!hideCancel" @click="cancel">Cancelar</div>
        <div class="accept" @click="accept">{{acceptText}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    msg: { require: true },
    hideCancel: { require: true },
    acceptText:{ require: false, default: 'Aceptar' },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    accept() {
      this.$emit("accept");
    },
  },
};
</script>

<style lang="scss">
#customization	.question-modal-container-message {
		padding-top: 10px;
		display: block;
	}

</style>
